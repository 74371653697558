<template>

  <v-container>

    <!-- Section intro -->
    <section id="intro">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%" color="red">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              En cas d'urgence
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
              Contactez la Police (17) ou si vous ne pouvez pas parler envoyez un SMS au 114. 
            </p>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>

    <!-- Section intro -->
    <section id="intro">
      <v-layout column wrap class="my-5" align-center>
        <v-card class="mx-auto" width="90%">
          <v-card-title class="section-title">
            <h1 class="display-2 font-weight-bold mb-3">
              Une question ? Besoin d'aide ?
            </h1>
          </v-card-title>
          <v-card-text
            class="body-1"
          >
            <p class="text--primary">
              Besoin d’un conseil ? Envie de nous rejoindre ? C’est ici !
            </p>
            <p class="text--primary">
              Lors de nos échanges, nous considérons que vous souhaitez rester anonyme sauf si vous nous indiquez le contraire.
              <br/>
              Votre e-mail ne sera pas diffusé mais utilisé à des fins de contact uniquement par une personne du comité. 
            </p>

            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >

                <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
                outlined
                ></v-text-field>

                <v-textarea
                label="Votre message"
                outlined
                required
                ></v-textarea>

                <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
                >
                Envoyer
                </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-layout>
    </section>


  </v-container>
</template>

<script>
  export default {
    name: 'NousContacter',

    data: () => ({
      
    }),
  }
</script>
